/* istanbul ignore file */
/* tslint:disable */

export enum OPCUANodeClassEnum {
  DATA_TYPE = 'DataType',
  METHOD = 'Method',
  OBJECT = 'Object',
  OBJECT_TYPE = 'ObjectType',
  REFERENCE_TYPE = 'ReferenceType',
  VARIABLE = 'Variable',
  VARIABLE_TYPE = 'VariableType',
  VIEW = 'View'
}
