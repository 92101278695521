/* istanbul ignore file */
/* tslint:disable */

export enum UsageType {
  ALARM_CHAIN = 'alarmChain',
  ALARM_CHAIN_OBSERVER = 'alarmChainObserver',
  ALERTING = 'alerting',
  DATA_POINT = 'dataPoint',
  DEVICE = 'device',
  GROUP = 'group',
  REPORT_JOB = 'reportJob',
  TICKET_RESPONSIBLE = 'ticketResponsible',
  WIDGET = 'widget'
}
