/* istanbul ignore file */
/* tslint:disable */

export enum UserField {
  ALARM_NOTIFICATION = 'alarmNotification',
  ALARM_SOUND = 'alarmSound',
  ATTENDANCE_COLOR = 'attendanceColor',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  IMAGE = 'image',
  LANGUAGE = 'language',
  LAST_NAME = 'lastName',
  LOGIN = 'login',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PHONE = 'phone',
  TIMEZONE = 'timezone',
  USER_CATEGORY = 'userCategory',
  USER_GROUPS = 'userGroups',
  USER_ROLE = 'userRole',
  USER_TITLE = 'userTitle'
}
