/* istanbul ignore file */
/* tslint:disable */

export enum EntityStatus {
  _0 = '0',
  _1 = '1',
  _2 = '2',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7'
}
