/* istanbul ignore file */
/* tslint:disable */

export enum WidgetType {
  ANCILLARY_COST_SETTLEMENT = 'ancillaryCostSettlement',
  BULLETIN_BOARD = 'bulletinBoard',
  CARBON_FOOTPRINT_EMISSION_BREAKDOWN = 'carbonFootprintEmissionBreakdown',
  CARBON_FOOTPRINT_MEDIA_REPORTING = 'carbonFootprintMediaReporting',
  CARBON_FOOTPRINT_PERFORMANCE = 'carbonFootprintPerformance',
  CARBON_FOOTPRINT_REDUCTION_PATHWAY = 'carbonFootprintReductionPathway',
  CHART = 'chart',
  EXTERNAL_CONTENT = 'externalContent',
  FAQ = 'faq',
  IMAGE = 'image',
  LAUNCH_PAD = 'launchPad',
  MAP = 'map',
  METER_NETWORK = 'meterNetwork',
  REAL_ESTATE_DETAIL = 'realEstateDetail',
  REAL_ESTATE_LIST = 'realEstateList',
  REPORTING = 'reporting',
  SCHEMATIC = 'schematic',
  SLA = 'sla',
  SLIDESHOW = 'slideshow',
  TABLE_REPORT = 'tableReport',
  TENANTS = 'tenants',
  TICKET_LIST = 'ticketList',
  TRACKING = 'tracking'
}
