/* istanbul ignore file */
/* tslint:disable */

export enum ComparisonOperator {
  ALWAYS = 'ALWAYS',
  EQUAL = 'EQUAL',
  GREATER = 'GREATER',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LOWER = 'LOWER',
  LOWER_OR_EQUAL = 'LOWER_OR_EQUAL',
  NOT_EQUAL = 'NOT_EQUAL'
}
