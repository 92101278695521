/* istanbul ignore file */
/* tslint:disable */

export enum SeriesType {
  AREA = 'area',
  AREA_SPLINE = 'area_spline',
  CARPET = 'carpet',
  COLUMN = 'column',
  GAUGE = 'gauge',
  LINE = 'line',
  PIE = 'pie',
  SCATTER = 'scatter',
  SPLINE = 'spline',
  STEP_LINE_CENTER = 'step_line_center',
  STEP_LINE_LEFT = 'step_line_left',
  STEP_LINE_RIGHT = 'step_line_right'
}
