/* istanbul ignore file */
/* tslint:disable */

export enum ExcelCellHorizontalAlignment {
  CENTER = 'CENTER',
  CENTER_SELECTION = 'CENTER_SELECTION',
  DISTRIBUTED = 'DISTRIBUTED',
  FILL = 'FILL',
  GENERAL = 'GENERAL',
  JUSTIFY = 'JUSTIFY',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}
