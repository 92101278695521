/* istanbul ignore file */
/* tslint:disable */

export enum PartitionType {
  APPLICATION_DATA = 'APPLICATION_DATA',
  CUSTOMER_DATA = 'CUSTOMER_DATA',
  DATABASE = 'DATABASE',
  DOCKER_BEETLE_DATA = 'DOCKER_BEETLE_DATA',
  LOGS = 'LOGS'
}
