/* istanbul ignore file */
/* tslint:disable */

export enum MonitoredResource {
  BEETLE_TLSCERTIFICATES = 'BeetleTLSCertificates',
  DATA_EXCHANGE_EXPORT = 'DataExchangeExport',
  DATA_EXCHANGE_IMPORT = 'DataExchangeImport',
  DISK_SPACE = 'DiskSpace',
  OPEN_VPNCERTIFICATES = 'OpenVPNCertificates',
  SERVER_TLSCERTIFICATES = 'ServerTLSCertificates'
}
