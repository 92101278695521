/* istanbul ignore file */
/* tslint:disable */

export enum AggregationTime {
  _15_MINUTES = '15_minutes',
  DAY = 'day',
  DAY_OF_MONTH = 'day_of_month',
  DAY_OF_WEEK = 'day_of_week',
  DAY_OF_WEEK_IN_MONTH = 'day_of_week_in_month',
  DAY_OF_YEAR = 'day_of_year',
  DEFAULT = 'default',
  HOUR = 'hour',
  HOUR_OF_DAY = 'hour_of_day',
  MINUTE = 'minute',
  MONTH = 'month',
  MONTH_OF_YEAR = 'month_of_year',
  QUARTER = 'quarter',
  QUARTER_OF_HOUR = 'quarter_of_hour',
  QUARTER_OF_YEAR = 'quarter_of_year',
  SEMESTER = 'semester',
  SEMESTER_OF_YEAR = 'semester_of_year',
  WEEK = 'week',
  WEEK_OF_YEAR = 'week_of_year',
  YEAR = 'year'
}
