/* istanbul ignore file */
/* tslint:disable */

export enum LinkType {
  DYNAMIC_TARGET = 'DYNAMIC_TARGET',
  EXTERNAL_URL = 'EXTERNAL_URL',
  NAVIGATE = 'NAVIGATE',
  NONE = 'NONE',
  OPEN_FLYOUT = 'OPEN_FLYOUT'
}
