/* istanbul ignore file */
/* tslint:disable */

export enum AuxiliaryType {
  EXPONENTIAL = 'exponential',
  LINEAR = 'linear',
  LOGARITHMIC = 'logarithmic',
  POLYNOMIAL = 'polynomial',
  SEGMENTED_LINEAR = 'segmented_linear'
}
