/* istanbul ignore file */
/* tslint:disable */

export enum AggregationType {
  AVG = 'avg',
  CONSUMPTION = 'consumption',
  CONSUMPTION_ALL = 'consumption_all',
  CONSUMPTION_NEGATIVE = 'consumption_negative',
  CONSUMPTION_POSITIVE = 'consumption_positive',
  DATAPOINT_CATEGORY = 'datapoint_category',
  DATE_FIRST_VALUE = 'date_first_value',
  DATE_LAST_VALUE = 'date_last_value',
  DEFAULT = 'default',
  FIRST_VALUE = 'first_value',
  INTERPOLATED_VALUE_AT_END_OF_INTERVAL = 'interpolatedValueAtEndOfInterval',
  INTERPOLATED_VALUE_AT_START_OF_INTERVAL = 'interpolatedValueAtStartOfInterval',
  LAST_VALUE = 'last_value',
  MAX = 'max',
  MIN = 'min',
  NR_OF_ALARMS = 'nr_of_alarms',
  RAW = 'raw',
  SUM = 'sum',
  TOTAL_DURATION = 'total_duration'
}
