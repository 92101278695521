/* istanbul ignore file */
/* tslint:disable */

export enum ThermoValveRadioCommunicationInterval {
  _5_MIN = '5-min',
  _10_MIN = '10-min',
  _60_MIN = '60-min',
  _120_MIN = '120-min',
  _480_MIN = '480-min'
}
