/* istanbul ignore file */
/* tslint:disable */

export enum ChartType {
  ALARM_SETUP_CATEGORY = 'alarm_setup_category',
  CARPET = 'carpet',
  DATAPOINT_CATEGORY = 'datapoint_category',
  DURATION_CURVE = 'duration_curve',
  GAUGE = 'gauge',
  MEASUREMENT = 'measurement',
  PIE = 'pie',
  SCATTER = 'scatter',
  TICKET_STATISTICS = 'ticket_statistics',
  TIME_CATEGORY = 'time_category'
}
