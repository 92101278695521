/* istanbul ignore file */
/* tslint:disable */

export enum ExcelCellColor {
  GREEN = 'GREEN',
  GREY = 'GREY',
  NONE = 'NONE',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  RED = 'RED',
  YELLOW = 'YELLOW'
}
