/* istanbul ignore file */
/* tslint:disable */

export enum BACnetPriorityLevel {
  AUTOMATION = 'automation',
  CONTROL_SYSTEM = 'control-system',
  MANUAL_INTERVENTION = 'manual-intervention',
  SAFETY_AND_SECURITY = 'safety-and-security',
  UNDEFINED = 'undefined'
}
