/* istanbul ignore file */
/* tslint:disable */

export enum UserCategory {
  CARETAKER = 'Caretaker',
  CLEANING = 'Cleaning',
  ENGINEER = 'Engineer',
  MANAGER = 'Manager',
  NONE = 'None',
  SUPPLIER = 'Supplier',
  TECHNICIAN = 'Technician'
}
