/* istanbul ignore file */
/* tslint:disable */

export enum TableReportColumnType {
  DATA_POINT = 'dataPoint',
  END_DATE_OF_INTERVAL = 'endDateOfInterval',
  PLAUSIBILITY_CHECK = 'plausibilityCheck',
  START_DATE_OF_INTERVAL = 'startDateOfInterval',
  SYSTEM_NAME = 'systemName',
  TEXT = 'text',
  UNIT = 'unit',
  VALUE = 'value',
  VALUE_DATE = 'valueDate',
  VALUE_NO_UNIT = 'valueNoUnit'
}
