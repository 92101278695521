/* istanbul ignore file */
/* tslint:disable */

export enum WagoUIStateType {
  X_CALCULATION = 'xCalculation',
  X_COMMUNICATION = 'xCommunication',
  X_ERROR = 'xError',
  X_HAND_OPERATION = 'xHandOperation',
  X_LICENSE_MISSING = 'xLicenseMissing',
  X_MAX_VALUE = 'xMaxValue',
  X_MIN_VALUE = 'xMinValue',
  X_PHY_MAX = 'xPhyMax',
  X_PHY_MIN = 'xPhyMin'
}
