/* istanbul ignore file */
/* tslint:disable */

export enum TicketListColumn {
  ALARMCHAIN = 'alarmchain',
  ALARMINSTRUCTION = 'alarminstruction',
  BUILDING = 'building',
  CREATED = 'created',
  DATAPOINTLABEL = 'datapointlabel',
  DUE = 'due',
  ID = 'id',
  MODIFIED = 'modified',
  REALESTATE = 'realestate',
  RESOLVED = 'resolved',
  RESPONSIBLE = 'responsible',
  SOURCE = 'source',
  STATUS = 'status',
  SYSTEMNAME = 'systemname',
  TAGS = 'tags',
  TEXT = 'text',
  TYPE = 'type'
}
