/* istanbul ignore file */
/* tslint:disable */

export enum ExcelCellStyle {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  FORMULA = 'FORMULA',
  NONE = 'NONE',
  NUMBER = 'NUMBER'
}
