/* istanbul ignore file */
/* tslint:disable */

export enum GroupDType {
  BUILDING = 'Building',
  MASTER_PAGE_GROUP = 'MasterPageGroup',
  PORTFOLIO = 'Portfolio',
  REAL_ESTATE = 'RealEstate',
  STRUCTURE_GROUP = 'StructureGroup'
}
