/* istanbul ignore file */
/* tslint:disable */

export enum BillingProductType {
  BEETLE = 'BEETLE',
  CLIENT = 'CLIENT',
  IN_HOUSE = 'IN_HOUSE',
  IOT = 'IOT',
  IOT_GATEWAY = 'IOT_GATEWAY',
  ON_SITE_PANEL_SERVER = 'ON_SITE_PANEL_SERVER',
  SUPPORT_HOUR = 'SUPPORT_HOUR',
  TABLET = 'TABLET',
  TRACKER = 'TRACKER',
  VIRTUAL_DEVICE = 'VIRTUAL_DEVICE',
  WAGO_750_MQTT = 'WAGO_750_MQTT',
  WEATHER = 'WEATHER'
}
